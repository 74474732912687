<template>
    <v-container fluid tag="section">
        <v-row justify="center">
            <v-col v-if="showYears" class="d-flex" cols="12" sm="4">
                <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" :menu-props="{ top: true, offsetY: false }" v-model="anio" :items="years" item-text="year"
                    item-value="year" label="Año" @change="loadDataNominas">
                </v-select>
            </v-col>
            <v-col cols="12" sm="12" style="padding:0px;margin:0px">
                <v-divider  />
            </v-col>
        </v-row>
     
        <v-row justify="center">
            <v-col v-if="showAlert" cols="12" sm="12">
                <v-alert align="center" dense min-height="25" type="warning" style="padding:0px">
                    <strong>No hay nóminas timbradas</strong>
                </v-alert>
            </v-col>
            <v-col v-else-if="showTable" cols="12" sm="12" class="bg-white border rounded shadow">
                <!--  -->
                <v-row justify="center"  >
                    <v-col cols="12" sm="12" style="padding:0px;margin:10px 0px">
                        <v-expansion-panels v-model="panel" multiple>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    
                                    <v-row align="center" justify="space-between" style="width: 100%;">
                                        <div>
                                            <h3 style="margin: 0;">TOTAL INGRESOS DEL {{ anio }}</h3>
                                        </div>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content> 
                                    <!-- Integrando la tabla -->
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th style="font-weight: bold;" colspan="5" class="text-center">
                                                      
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th class="text-left" style="font-weight: bold;">
                                                        <h3>Percepciones</h3>
                                                    </th>
                                                    <th class="text-center" style="font-weight: bold;">
                                                        <h3>ISR</h3>
                                                    </th>
                                                    <th class="text-right" style="font-weight: bold;">
                                                        <h3>Deducciones</h3>
                                                    </th>
                                                    <th class="text-right" style="font-weight: bold;">
                                                        <h3>Subsidio al empleo</h3>
                                                    </th>
                                                    <th class="text-right" style="font-weight: bold;">
                                                        <h3>Líquido</h3>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-left" style="font-weight: bold;">
                                                        <h3>${{ toCurrency(data_importes.total_percepcion) }}</h3>
                                                    </td>
                                                    <td class="text-center" style="font-weight: bold;">
                                                        <h3>${{ toCurrency(data_importes.total_isr) }}</h3>
                                                    </td>
                                                    <td class="text-right" style="font-weight: bold;">
                                                        <h3>${{ toCurrency(data_importes.total_deducciones) }}</h3>
                                                    </td>
                                                    <td class="text-right" style="font-weight: bold;">
                                                        <h3>${{ toCurrency(data_importes.total_subsidio) }}</h3>
                                                    </td>
                                                    <td class="text-right" style="font-weight: bold;">
                                                        <h3>${{ toCurrency(((data_importes.total_percepcion + data_importes.total_subsidio) - data_importes.total_deducciones)) }}</h3>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <!-- Fin de la integración de la tabla -->
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
                <!--  -->
                <v-client-table :data="data" :columns="columns" :options="options" ref="table" style="text-align:center">
                    <template slot="primeraQuincena" slot-scope="nomina">
                        <template v-if="nomina.row.primeraQuincena">
                            <template v-for="(item, index) in nomina.row.primeraQuincena">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#e70c0e" small @click="imprimirPDF(nomina.row,1,index,1,'ordinaria')">
                                            <v-icon>fas fa-file-pdf</v-icon>PDF
                                        </v-btn>
                                    </template>

                                    <span>Descargar PDF</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,index,1,'ordinaria')">
                                            <v-icon>fas fa-file-excel</v-icon>XML
                                        </v-btn>
                                    </template>
                                    <span>Descargar XML</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#0088ff" small @click="vista_previa_nomina(nomina.row.primeraQuincena,2)">
                                            <v-icon>fa-file</v-icon>Recibo
                                        </v-btn>
                                    </template>
                                    <span>Vista previa</span>
                                </v-tooltip>              
                              </template>
                        </template>
                        <template v-else>
                            <strong>NO HAY CFDI PARA ESTA FECHA</strong>
                        </template>
                    </template>
                    <template slot="segundaQuincena" slot-scope="nomina">
                        <template v-if="nomina.row.segundaQuincena">
                            <template v-for="(item, index) in nomina.row.segundaQuincena">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                      <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(nomina.row,1,index,2,'ordinaria')">
                                          <v-icon>fas fa-file-pdf</v-icon>PDF
                                      </v-btn>
                                  </template>
                                  <span>Descargar PDF</span>
                              </v-tooltip>

                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                      <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,index,2,'ordinaria')">
                                          <v-icon>fas fa-file-excel</v-icon>XML
                                      </v-btn>
                                  </template>
                                  <span>Descargar XML</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#0088ff" small @click="vista_previa_nomina(nomina.row.segundaQuincena,2)">
                                            <v-icon>fa-file</v-icon>Recibo
                                        </v-btn>
                                    </template>
                                    <span>Vista previa</span>
                                </v-tooltip>              
                            </template>
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>

                        </template>
                    </template>

                    <template slot="primeraQuincenaCompensacion" slot-scope="nomina">
                        <template v-if="nomina.row.primeraQuincenaCompensacion">
                            <template v-for="(item, index) in nomina.row.primeraQuincenaCompensacion">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#e70c0e" small @click="imprimirPDF(nomina.row,1,index,1,'compensacion')">
                                            <v-icon>fas fa-file-pdf</v-icon>PDF
                                        </v-btn>
                                    </template>

                                    <span>Descargar PDF</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,index,1,'compensacion')">
                                            <v-icon>fas fa-file-excel</v-icon>XML
                                        </v-btn>
                                    </template>
                                    <span>Descargar XML</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#0088ff" small @click="vista_previa_nomina(nomina.row.primeraQuincenaCompensacion,1)">
                                            <v-icon>fa-file</v-icon>Recibo
                                        </v-btn>
                                    </template>
                                    <span>Vista previa</span>
                                </v-tooltip>              
                              </template>
                        </template>
                        <template v-else>
                            <strong>NO HAY CFDI PARA ESTA FECHA</strong>
                        </template>
                    </template>
                    <template slot="segundaQuincenaCompensacion" slot-scope="nomina">
                        <template v-if="nomina.row.segundaQuincenaCompensacion">
                            <template v-for="(item, index) in nomina.row.segundaQuincenaCompensacion">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(nomina.row,1,index,2,'compensacion')">
                                            <v-icon>fas fa-file-pdf</v-icon>PDF
                                        </v-btn>
                                    </template>
                                    <span>Descargar PDF</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#f57304" small @click="imprimirXML(nomina.row,1,index,2,'compensacion')">
                                            <v-icon>fas fa-file-excel</v-icon>XML
                                        </v-btn>
                                    </template>
                                    <span>Descargar XML</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" color="#0088ff" small @click="vista_previa_nomina(nomina.row.segundaQuincenaCompensacion,1)">
                                            <v-icon>fa-file</v-icon>Recibo
                                        </v-btn>
                                    </template>
                                    <span>Vista previa</span>
                                </v-tooltip>              
                            </template>
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>

                        </template>
                    </template>
                    <template v-slot:extraordinaria="{row,index}" >
                        <template v-if="row.extraordinaria">
                            <v-select color="blue-grey lighten-4" solo item-color="blue-grey lighten-4" :menu-props="{ top: true, offsetY: false }" @change="cambiarSelect($event,index)"  :items="extraordinariasItems(row.extraordinaria)" item-text="year"
                              item-value="year" label="Nómina extraordinaria" >
                            </v-select>
                            <v-tooltip bottom v-if="row.selectedExtraordinaria !== 0">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" small color="#e70c0e" @click="imprimirPDF(row,2,0,'')">
                                        <v-icon>fas fa-file-pdf</v-icon>PDF
                                    </v-btn>
                                </template>
                                <span>Descargar PDF</span>
                            </v-tooltip>

                            <v-tooltip bottom v-if="row.selectedExtraordinaria !== 0">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="#f57304" small @click="imprimirXML(row,2,0,'')">
                                        <v-icon>fas fa-file-excel</v-icon>XML
                                    </v-btn>
                                </template>
                                <span>Descargar XML</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="row.selectedExtraordinaria !== 0">
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="#0088ff" small @click="vista_previa_nomina_ext(row)">
                                        <v-icon>fa-file</v-icon>Recibo
                                    </v-btn>
                                </template>
                                <span>Vista previa</span>
                            </v-tooltip> 
                        </template>
                        <template v-else>
                            <v-row justify="center">
                                <strong >NO HAY CFDI PARA ESTA FECHA</strong>
                            </v-row>

                        </template>
                    </template> 
                </v-client-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data:function(){
        return {
            //panel de avisos
            panel: [],
            //banderas para mostrar lo diferentes componentes
            showYears:false,
            showAlert:false,
            showTable:false,
             
            columns: ["mes","primeraQuincena","segundaQuincena","primeraQuincenaCompensacion","segundaQuincenaCompensacion","extraordinaria"],
            options: {
                headings: {
                    mes             : 'Mes',
                    primeraQuincena : "Primera quincena",
                    segundaQuincena : "Segunda quincena",
                    primeraQuincenaCompensacion : "Primera quincena compensación",
                    segundaQuincenaCompensacion : "Segunda quincena compensación",
                    extraordinaria  : "Extraordinarias", 

                },
                sortable: [],
                texts: {
                    count:"",
                    filter:"Buscar:",
                    filterPlaceholder: 'Buscar...',
                    limit:"Resultados:",
                    recordsDisplay: 'Resultados',
                    page:"Página:",
                    noResults:"No hay resultados",
                    filterBy: 'Buscar...',
                    loading:'Cargando...',
                    defaultOption: 'Seleccionar {column}'
                },
                filterable: false,
                filterByColumn: false,
                resizableColumns: false,
                sendEmptyFilters :false,
                childRowTogglerFirst: true,
                showChildRowToggler: true,
                perPage: 10,
                perPageValues: [5, 10, 15, 20],

                templates:{
                }
            },

            anio:null,
            years:[],
            dataNominas:[],
            data:[],
            currentYear:null,
            showCaroucel :false,
            array_caroucel : [],
            data_importes : [],
        }
    },
    computed:{
        ...mapState(["user","token","baseUrl"]),
    },
    async mounted(){
        this.$NProgress.done();
        this.currentYear = new Date().getFullYear()
        

        await this.loadYears()


    },
    methods: { 
        async loadYears(){
            this.years = [];
            this.dataNominas = [];
            this.data = []
            this.anio = null;
            this.showYears = false;
            this.showAlert = false;
            this.showTable = false;
            try{
                let form = new FormData();
                // form.append("tipo_nomina",this.tipoNomina);
                form.append("id_empleado",this.user.id_empleado);
                form.append("rfc",this.user.rfc);
                let response = await this.$http.post("get-years",form);
                if(response.data.response){
                    this.years = response.data.years;

                    this.years.forEach((el)=>{
                      if(el == this.currentYear){
                        this.anio = `${this.currentYear}`
                        this.loadDataNominas()
                      }
                    })
                    this.showYears = true;
                } else {
                    this.years = [];
                    this.showAlert = false;
                }

            }catch(error){
                this.years = [];
            }
        },
        async loadDataNominas(){
            this.showTable = false;
            this.showAlert = false;
            this.dataNominas = []
            this.data = []

             try{
                  let form = new FormData();
                  form.append("id_empleado",this.user.id_empleado);
                  form.append("anio",this.anio);
                  form.append("rfc",this.user.rfc);
                  let response = await this.$http.post("get-datos-nomina-ord-comp",form);
                  if(response.data.response){
                      this.dataNominas = response.data.data;
                      for (const property in this.dataNominas) {
                        this.dataNominas[property].selectedExtraordinaria = 0 
                        this.data.push(this.dataNominas[property])
                      }
                      this.data_importes = response.data.importes

                      this.showTable = true;
                  }else {
                      this.showAlert = false;
                  }

            }catch(error){
                this.dataNominas = [];
                this.data = [];

                this.showTable = true;
            }
        },
        imprimirPDF(data,tipo,index, quincena = null,tipo_nomina = null){
            var extension = '.pdf';
            var file_path = "";
            if(tipo == 1){
                let selected = null
                if(quincena == 1){
                    if(tipo_nomina == 'ordinaria'){
                        selected = data.primeraQuincena
                    }else{
                        selected = data.primeraQuincenaCompensacion
                    }
                  
                }else{
                    if(tipo_nomina == 'ordinaria'){
                        selected = data.segundaQuincena
                    }else{
                        selected = data.segundaQuincenaCompensacion
                    }
                   
                }

                file_path = "assets/cfdi/timbrados/"+selected[index].file_path+"pdf/"+selected[index].file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected[index].file_name+"&file_path="+file_path+"&extension="+extension,"_blank");

            }else if(tipo == 2){
                let seleccionado = data.selectedExtraordinaria

                let selected = data.extraordinaria.filter((el)=> el.nombre == seleccionado)[index]

                file_path =  'assets/cfdi/timbradosNominaExtraordinaria/'+selected.file_path+'pdf/'+selected.file_name+extension;
                if(selected.file_path == null){
                    file_path =  'assets/cfdi/timbradosNominaExtraordinaria/pdf/'+selected.file_name+extension;
                }


                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected.file_name+"&file_path="+file_path+"&extension="+extension,"_blank");
            } 
        },
        imprimirXML(data,tipo, index, quincena = null,tipo_nomina = null){
            var extension = '.xml';
            var file_path = "";
            if(tipo == 1){ //si es diferente de extraordinaria

                let selected = null
                if(quincena == 1){
                    if(tipo_nomina == 'ordinaria'){
                        selected = data.primeraQuincena
                    }else{
                        selected = data.primeraQuincenaCompensacion
                    }
                  
                }else{
                    if(tipo_nomina == 'ordinaria'){
                        selected = data.segundaQuincena
                    }else{
                        selected = data.segundaQuincenaCompensacion
                    }
                   
                }

                file_path = "assets/cfdi/timbrados/"+selected[index].file_path+"xml/"+selected[index].file_name+extension;
                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected[index].file_name+"&file_path="+file_path+"&extension="+extension, "_blank");
            }else if(tipo == 2){
                let seleccionado = data.selectedExtraordinaria

                let selected = data.extraordinaria.filter((el)=> el.nombre == seleccionado)[index]

                file_path =  'assets/cfdi/timbradosNominaExtraordinaria/'+selected.file_path+'xml/'+selected.file_name+extension;
                if(selected.file_path == null){
                    file_path =  'assets/cfdi/timbradosNominaExtraordinaria/xml/'+selected.file_name+extension;
                }

                window.open(this.baseUrl + "download-files?token="+this.token+"&file_name=" + selected.file_name+"&file_path="+file_path+"&extension="+extension, "_blank");
            } 
        },

        extraordinariasItems(extraordinarias){
          let filter = [null];
          extraordinarias.forEach((el)=>{
            filter.push(el.nombre)
          })
          return filter
        },
      
        cambiarSelect(event,index){
          this.data[index-1].selectedExtraordinaria = event

          this.data =[...this.data];
          this.$forceUpdate();
        },

        

        redirigir(item){
          if(item.has_redirect == "1"){
            window.open(item.url_redirect, '_blank');
          }
        },
        toCurrency(value){
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        vista_previa_nomina: function(nomina,tipoNomina){

            const form = document.createElement('form');
            form.target = "_blank";
            form.method = 'post';
            form.id = 'form';
            form.action = `${this.baseUrl}vista-previa-nomina?token=${this.token}`;

            const id_empleado = document.createElement('input')
            id_empleado.type = 'hide';
            id_empleado.name = 'id_empleado';
            id_empleado.value = nomina[0].id_empleado;
            form.append(id_empleado);

            const id_nomina = document.createElement('input')
            id_nomina.type = 'hide';
            id_nomina.name = 'id_nomina';
            id_nomina.value = nomina[0].id_nomina;
            form.append(id_nomina);

            let id_tipo_nom = 'C';
            if(tipoNomina == 1){ //Compensacion
                id_tipo_nom = 'CC';
            }
            const id_tipo_nomina = document.createElement('input')
            id_tipo_nomina.type = 'hide';
            id_tipo_nomina.name = 'id_tipo_nomina';
            id_tipo_nomina.value = id_tipo_nom;
            form.append(id_tipo_nomina);

            document.querySelector('body').append(form);
            //-----------------------------------------------------------------------------------------------------
            form.submit();
            document.querySelector('body').removeChild(document.getElementById('form'))
        },
        vista_previa_nomina_ext: function(data){
            let seleccionado = data.selectedExtraordinaria

            let nomina = data.extraordinaria.filter((el)=> el.nombre == seleccionado)[0]

            const form = document.createElement('form');
            form.target = "_blank";
            form.method = 'post';
            form.id = 'form';
            form.action = `${this.baseUrl}vista-previa-nomina-ext?token=${this.token}`;

            const id_empleado = document.createElement('input')
            id_empleado.type = 'hide';
            id_empleado.name = 'id_empleado';
            id_empleado.value = nomina.id_empleado;
            form.append(id_empleado);

            const id_nomina = document.createElement('input')
            id_nomina.type = 'hide';
            id_nomina.name = 'id_nomina';
            id_nomina.value = nomina.id_nomina_e;
            form.append(id_nomina);

            document.querySelector('body').append(form);
            //-----------------------------------------------------------------------------------------------------
            form.submit();
            document.querySelector('body').removeChild(document.getElementById('form'))
        },

    }

}
</script>
